:root {
    --color-fg: #333;
    // Weaken, by making it lighter
    --color-fg2: #666;

    --color-bg: #eee;
    --color-bg2: #f4f4f4;
    --color-bg3: #f0f0f0;
    --color-bg30: #ccc;
    --color-bg30-border: #ddd;
    --color-bg60: #888;
    --color-bg60-border: #ddd;
    --color-highlight-bg: #00aa00;
    --color-highlight-fg: #00aa00;

    --color-fg-link: #363;
    --color-fg-active: #383;
    --color-fg-current: #3a3;
    --color-fg-hover: #3a3;

    --color-nav-bg: #efe;
    --color-shadow: #aaa;
    --color-split: #ccc;
    --color-split2: #bbb;
    
    --color-blue-bg: #88f;
}
@media (prefers-color-scheme: dark) {
    :root {
        --color-fg: #ccc;
        // Weaken, by making it darker
        --color-fg2: #aaa;

        --color-bg: #111;
        --color-bg2: #333;
        --color-bg3: #444;
        --color-bg30: #222;
        --color-bg30-border: #333;
        --color-bg60: #333;
        --color-bg60-border: #666;
        --color-highlight-bg: #006600;

        --color-fg-link: #6c6;
        --color-fg-active: #8e8;
        --color-fg-current: #8e8;
        --color-fg-hover: #8e8;

        --color-nav-bg: #121;
        --color-shadow: #000;
        --color-split: #444;
        --color-split2: #000;

        --color-blue-bg: #225;
    }
}
