// When width is smaller than threshold, use collapsed mobile nav menu
@media (max-width: 1200px) {
    body {
        grid-template:  "menu" auto "main" auto / auto;
    }

    #header {
        display: grid;
        grid-template: "navhandle . logo ." auto "menu menu menu menu" auto / auto $spacing-big auto 1fr;
        padding: 0;

        #nav-handle {
            grid-area: navhandle;
            display: block;
        }
        #logo {
            grid-area: logo;
        }
        #nav {
            grid-area: menu;
        }
    }
    #nav {
        visibility: collapse;
        opacity: 0;
    }
    #nav-show:checked ~ #nav {
        visibility: visible;
        opacity: 1;
        transition: opacity 400ms ease;
    }
    #nav {
        background-color: var(--color-bg);
        padding: 1em;
        font-size: 15px;
        line-height: 29px;
        position: absolute;
        z-index: 1;
        top: 100px;
        left: 0;
        right: 0;
        transition: opacity 400ms ease, visibility 0s 400ms;
        .menublock {
            padding: 15px 31px 15px;
            line-height: inherit;
        }
        a {
            padding: 0;
        }
        a:hover {
            color: var(--color-fg-hover);
        }
    }
}
