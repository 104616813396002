table {
    border-collapse: collapse;

    tr {
        background: var(--color-bg2);
        &:nth-child(odd) {
            background: var(--color-bg3);
        }
    }
    th, td {
        border: 1px solid var(--color-split2);
        padding: 6px 8px;
        text-align: left;
        vertical-align: top;
    }
}
