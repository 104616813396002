

.groupbox {
    $spacingv: 12px;
    $spacingh: 8px;

    border: 1px solid var(--color-split);
    position: relative;
    margin: 1em 0 0.5em;
    padding: $spacingv + $spacingv $spacingh $spacingv;

    .groupbox-label {
        display: inline-block;
        line-height: 1em;
        position: absolute;
        top: -0.5em;
        left: $spacingh;
        background-color: var(--color-bg2);
        border: 1px solid var(--color-split);
        margin: 0;
        padding: 2px 8px;
    }
}
