$spacing-small: 4px;
$spacing-normal: 6px;
$spacing-big: 8px;

#main {
    font-size: 1em;
    line-height: 1.2em;
    color: var(--color-fg);

    article {
        a:link, a:visited {
            border-bottom: 1px dashed black;
        }
        @import "content/table";
    }

    .article-meta {
        color: var(--color-fg2);
        font-style: italic;
        margin-bottom: 1em;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: $spacing-big 0 0;
        font-weight: 600;
    }
    h1 {
        font-size: 1.6em;
        line-height: 1.8em;
    }
    h2 {
        font-size: 1.4em;
        line-height: 1.6em;
    }
    h3 {
        font-size: 1.3em;
    }
    h4 {
        font-size: 1.2em;
    }
    h5, h6 {
        font-size: 1.1em;
    }
    table,
    figure,
    ul,
    .highlight {
        margin: 1em 0;
    }
    p {
        line-height: 1.4em;
        margin: 0.6em 0;
    }
    details, pre {
        margin: 0.6em 0;
    }
    blockquote {
        padding-left: 15px;
        border-left: 3px solid var(--color-split);
    }
    q {
        font-style: italic;
    }
    hr {
        margin: $spacing-big 0;
        border: 1px dashed var(--color-split);
    }
    li {
        margin-left: 12px;
        line-height: 1.3em;
    }
    select {
        background-color: var(--color-bg2);
    }
    a {
        &:link, &:visited {
            color: var(--color-fg-link);
        }
        &:hover {
            color: var(--color-fg-hover);
        }
    }
    /* Add icon to external links */
    a[href^="http"]:after {
        content: '';
        content: "" url(/images/icons/link-external-grey.png);    
        margin-left: 2px;
    }

    /* Expandable details: 1. make interactivity obvious through cursor pointer and hover highlight, 2. add background for layout element separation, 3. add spacing for hierarchy and separation */
    details {
        background: var(--color-bg2);
        padding-left: 1em;
        padding-right: 1em;
    }
    details > summary {
        cursor: pointer;
        padding: 0.2em 1em;
        margin-left: -1em;
        margin-right: -1em;
    }
    details > summary:hover {
        background: var(--color-bg3);
    }
    details[open] {
        padding-bottom: 0.5em;
    }
    details[open] {
        margin-bottom: 0.5em;
    }

    @import "content/figure";
    @import "components/images-showcase";
    // figure {
    //     // display: flex;
    //     // flex-direction: column;
    //     // flex-wrap: wrap;
    //     display: grid;
    //     grid-template-areas: "content"
    //                         "caption";
    //     grid-template-rows: auto auto;
    //     grid-template-columns: auto;
    //     > * {
    //         grid-area: content;
    //         display: inline;
    //     }
    //     figcaption {
    //         grid-area: caption;
    //     }
    //     // > * {
    //     //     display: inline;
    //     // }
    // }

    @import "components/groupbox";

    video {
        max-width: 100%;
    }

    .image-gallery {
        display: flex;
        gap: 1em;
    }
}
