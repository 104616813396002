.images-showcase {
    figure {
        float: left;
        margin: 20px;
    }
    img {
        max-width: 200px;
        max-height: 200px;
    }
}
.images-showcase {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    > * {
        flex: 1 100px;
    }
    img {
        max-width: 200px;
        max-height: 200px;
    }
}
