/* Reference: https://sass-lang.com/guide */

@import "colors";

* {
    margin: 0;
    padding: 0;

    font-size: inherit;
    line-height: inherit;
    background: none;
    color: inherit;
}
html {
    /* Scale to at least full window size so we can use a background banner covering the full window */
    min-width: 100%;
    min-height: 100%;

    background-color: var(--color-bg);
    color: var(--color-fg);

    font-family: 'Segoe UI', Arial, sans-serif;
    font-size: 100%;
    line-height: 1.1em;
}
a {
    &:link, &:visited {
        text-decoration: none;
    }
    &:hover {
        color: var(--color-fg-hover);
    }
}

// hugo fixup
@import "hugo";
pre {
    overflow: scroll;
}

mark {
    background: var(--color-highlight-bg);
}

@import "layout";
@import "sidemenu";
@import "menu";
@import "content";
@import "components/label";
@import "components/curation-card";
