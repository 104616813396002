ul#sidemenu-buttons {
    li {
        display: block;
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 22px;
        border-radius: 0 12px 12px 8px;
        background: #533200;
        padding: 2px;
        margin-bottom: 4px;
        cursor: pointer;

        box-shadow: 0 1px 8px var(--color-shadow);
        -webkit-user-select: none;
        user-select: none;
    }
}
