.curation-cards {
    display: flex;
    list-style: none;
    flex-flow: wrap;
    gap: 1em;

    & > * {
        margin: 0 !important;
    }
}
.curation-card {
    border: 1px solid var(--color-bg60-border);
    background: var(--color-bg60);
    position: relative;

    .producttypelabel {
        position: absolute;
        top: 0;
        right: 0;
        //transform: rotate(45deg);
        background-color: var(--color-blue-bg);
        color: var(--color-fg);
        padding: 2px 4px;
    }
    .cover {
        width: 200px;
        height: 286px;
        overflow: hidden;

        background: #222;
        display: grid;
        justify-content: center;
        align-content: center;
        text-align: center;
        font-size: 2em;
        line-height: 2em;
        color: #ccc;
        &:hover {
            color: #eee;
        }

        img {
            object-fit: cover;
            object-position: center center;
            height: 100%;
            width: 100%;
        }
    }
    a {
        display: block;
        width: 200px;
        padding: 16px 20px;
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: 1em !important;
        word-wrap: break-word;
        line-height: 1.2em !important;

        .titlejp {
            font-size: 1rem;
        }
    }
}
