.label {
    display: inline-block;
    border: 1px solid var(--color-bg30-border);
    border-radius: 4px;
    background-color: var(--color-bg30);
    padding: 0 6px;
    margin-right: 4px;
    line-height: 1.2em;

    &.label-linked {
        padding: 0 0px;
        
        a {
            display: block;
            padding: 0 6px;
        }
    }
}
