.menublock {
    font-size: 1.2em;
    line-height: 1.2em;
    background-color: var(--color-nav-bg);
    border-radius: 0px 0px 8px 4px;
    padding: 6px;
    margin-top: 1em;

    & > .title {
        border-bottom: 1px solid var(--color-split);
        padding-bottom: 0.2em;
    }
    hr {
        margin: 0.5em 0;
        border: 1px dotted var(--color-split);
    }

    #nav-referenzen {
        margin-bottom: 25px;
    }
    a {
        display: block;
        padding: 2px 2px 2px 2px;
    }
    ul, ol {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    li li {
        padding-bottom: 4px;
    }
    li li a {
        padding: 2px 2px 2px 8px;
    }
    .active > a {
        color: var(--color-fg-active);
    }
    .current > a, .active > a {
        color: var(--color-fg-current);
    }
}

/* Hidden checkbox that toggles visibility of collapsible menu */
#nav-show {
    display: none;
}
$nh-line-length: 26px;
$nh-line-width: 4px;
$nh-line-spacing: 5px;
$nh-height: 3 * $nh-line-width + 2 * $nh-line-spacing;
#nav-handle {
    display: none;
    cursor: pointer;
    position: relative;
    height: 3 * ($nh-line-width + $nh-line-spacing) + 9px;
    width: 32px;
    top: 12px;

    .nh-line1,
    .nh-line2,
    .nh-line3 {
        width: $nh-line-length;
        height: $nh-line-width;
        // margin: $nh-line-spacing 0 0 auto;
        background-color: var(--color-fg2);
        transition: top 200ms, transform 200ms, opacity 200ms;
    }
    .nh-line1 {
        position: absolute;
        top: 0 * ($nh-line-width + $nh-line-spacing);
        right: 0;
    }
    .nh-line2 {
        position: absolute;
        top: 1 * ($nh-line-width + $nh-line-spacing);
        right: 0;
    }
    .nh-line3 {
        position: absolute;
        top: 2 * ($nh-line-width + $nh-line-spacing);
        right: 0;
    }
    .nh-text {
        position: absolute;
        top: 3 * ($nh-line-width + $nh-line-spacing);
        right: 0;

        text-align: center;
        font-size: 9px;
        line-height: 1em;
        -webkit-user-select: none;
        user-select: none;
    }
}
#nav-show:checked ~ #nav-handle {
    .nh-line1 {
        top: 1 * ($nh-line-width + $nh-line-spacing);
        transform: rotate(45deg);
    }
    .nh-line2 {
        opacity: 0;
    }
    .nh-line3 {
        top: 1 * ($nh-line-width + $nh-line-spacing);
        transform: rotate(-45deg);
    }
}