$header-width: 236px;
$min-website-width: 360px;
$spacing-big: 28px;

@mixin container() {
    box-shadow: 0 1px 10px var(--color-shadow);
    margin: 10px;
}
@mixin container-block() {
    @include container();
    border-radius: 10px;
}

body {
    display: grid;
    margin: $spacing-big;
    gap: $spacing-big;
    grid-template: "menu main" / $header-width 1fr;
    min-width: 360px;
    #header {
        grid-area: menu;
    }
    #main {
        grid-area: main;
        max-width: 1024px;
    }
}
#header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: normal;

    #logo {
        @include container();
        img {
            display: block;
        }
    }
    .menublock {
        @include container-block();
    }
}
#main {
    padding: 2rem 2rem 2rem;

    .parent-page-link {
        border-bottom: 1px solid var(--color-split);
    }

    .taxonomies {
        border-top: 1px solid var(--color-split);
        margin-top: 1em;
        padding-top: 0.5em;
        font-size: 1em;
        line-height: 2em;
    }

    .subpage-links.blog-posts {
        .subpage-linked {
            list-style-type: none;
            margin: 0;
            margin: 1em 0;
            padding-bottom: 1em;
            border-bottom: 1px solid var(--color-split);

            display: grid;
            grid-template-columns: auto;
            gap: 0.4em;
            line-height: 1.4em;

            .title {
                margin: 0;
            }
            .meta, .meta-categories {
                font-size: 0.9em;
            }
        }
    }
}

.social-links-block {
    margin-top: 1rem;
    line-height: 1.8rem;
    .social-links {
        & > div {
            display: flex;
            gap: 4px;
            flex-flow: wrap;
        }
        a, img {
            display: inline;
        }
        a {
            margin-left: 4px;
        }
    }
}
@media (prefers-color-scheme: dark) {
    .social-links .github {
        filter: grayscale(1) invert(1);
    }
}

#search-results {
    position: absolute; 
    background: var(--color-bg);
    width: 50em;
    max-width: 80%;
    z-index: 2;
}
